import React, { useRef } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import logo from "./logo.svg";
import logo2 from "./Logo_of_UNICEF.svg";
import Header from "./components/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BarChart from "./BarChart";
import Popup from "reactjs-popup";
import ImageSlider from "./ImageSlider";
import "./styles.css";
import {
  FaRegFileAlt,
  FaTrophy,
  FaTasks,
  FaProjectDiagram,
  FaBuilding,
  FaExclamationTriangle,
  FaLightbulb,
  FaMapSigns,
} from "react-icons/fa";
const images = [
  "https://gh.hoope.me/ph/1.jpg",
  "https://gh.hoope.me/ph/2.jpg",
  "https://gh.hoope.me/ph/3.jpg",
  "https://gh.hoope.me/ph/4.jpg",
];
const centers = [
  "المركز الأول",
  "المركز الثاني",
  "المركز الثالث",
  "المركز الرابع",
  "المركز الخامس",
  "المركز السادس",
  "المركز السابع",
  "المركز الثامن",
  "المركز التاسع",
  "المركز العاشر",
  "المركز الحادي عشر",
  "المركز الثاني عشر",
  "المركز الثالث عشر",
  "المركز الرابع عشر",
  "المركز الخامس عشر",
  "المركز السادس عشر",
];

const ptypes = ["مهارات الحياة", "التمكين المهني", "المبادرات المجتمعية"];

function App() {
  const iconStyle = { marginLeft: "8px" }; // Adjust the margin as needed
  const ref = useRef(null);
  const closePopup = () => ref.current.close();
  return (
    <Container dir="rtl">
      <Container className="border border-light rounded-4 shadow-lg p-3 mt-3 mb-3">
        <Row>
          <Col>
            <div className="logo_img justify-content-center">
              <img src={logo} alt="" />
            </div>{" "}
          </Col>
          <Col sm={8} className="justify-content-center titel">
            <h2 className="text-center text-white">
              برنامج الاغاثة والخدمات الاجتماعية
              <br></br>
              مشروع اليافعيين
            </h2>
          </Col>
          <Col>
            <div className="logo_img2 justify-content-center">
              <img src={logo2} alt="" />
            </div>{" "}
          </Col>
        </Row>
      </Container>
      <Container className="border border-light rounded-4 shadow-lg p-3 mt-3 mb-3 lateef">
        <Row>
          <Col className="m-1">
            <Row>
              <Container className="border border-light rounded-4 shadow-lg p-3 mt-3 mb-3 ">
                <h3>
                  <FaRegFileAlt style={iconStyle} color="white" />
                  لمحة عن المشروع
                </h3>
                <h4>
                  هو برنامج يهدف إلى تعزيز مهارات الشباب وتمكينهم في مختلف جوانب
                  حياتهم. يركز المشروع على تنمية القدرات المهنية، تعزيز المهارات
                  الحياتية، وتشجيع المشاركة في المبادرات المجتمعية، بهدف بناء
                  جيل قادر على مواجهة التحديات وتحقيق التغيير الإيجابي في
                  مجتمعاتهم.
                </h4>
              </Container>
            </Row>
            <Row sm={8}>
              {" "}
              <Container className="border border-light rounded-4 shadow-lg p-3 mt-3 mb-3">
                <h3>
                  {" "}
                  <FaBuilding style={iconStyle} color="white" />
                  المراكز المجتمعية في المخيمات
                </h3>
                {centers.map((center, index) => (
                  <h4 className="m-2">
                    <Popup
                      ref={ref}
                      trigger={
                        <button className="popupbtn">
                          <li key={index}>{center}</li>
                        </button>
                      }
                      position="top center"
                      className="eventscard"
                      arrow={false}
                      modal
                      closeOnDocumentClick={false}
                    >
                      <button
                        className="close bg-indicatorbackground"
                        onClick={closePopup}
                      >
                        X
                      </button>
                      <ImageSlider images={images} />
                      <h4 className="text-white text-end lateef">
                        يركز المشروع على بناء القدرات المهنية وتعزيز المهارات
                        الحياتية الأساسية مثل التفكير النقدي وحل المشكلات، مع
                        تشجيع اليافعين على المشاركة الفعالة في المبادرات
                        المجتمعية. يهدف المشروع إلى تأهيل جيل قادر على التكيف مع
                        التحديات المستقبلية والمساهمة في إحداث تأثير إيجابي
                        ومستدام في مجتمعاتهم.
                      </h4>
                    </Popup>
                  </h4>
                ))}
              </Container>
            </Row>
          </Col>
          {/*  ///////////////////////////////////// */}
          <Col className="m-1">
            <Row>
              {" "}
              <Container className="border border-light rounded-4 shadow-lg p-3 mt-3 mb-3">
                <h3>
                  {" "}
                  <FaTrophy style={iconStyle} color="white" />
                  إنجازات المشروع
                </h3>
              </Container>
            </Row>
            <Row>
              {" "}
              <Container className="border border-light rounded-4 shadow-lg p-3 mt-3 mb-3">
                <h3>
                  <FaProjectDiagram style={iconStyle} color="white" />
                  نفقات المشروع
                </h3>
                <BarChart />
              </Container>
            </Row>
            <Row>
              {" "}
              <Container className="border border-light rounded-4 shadow-lg p-3 mt-3 mb-3">
                <h3>
                  {" "}
                  <FaLightbulb style={iconStyle} color="white" />
                  التوصيات
                </h3>
                <h4>
                  مشى الحكيم الشهير على الرصيف وهو ينظر إلى حذائه، لاحظ أنه
                  عندما يمشي يسبقه حذاؤه، لا يستطيع رأسه أو أي جزء من جسمه أن
                  يسبق الحذاء، شعر بالإهانة، كيف يسبقه حذاء؟ وقف تحت شجرة يفكر،
                  رأى قطة، مدهش! هذا هو الحل، وضع يديه على الأرض ومشى كما تمشي
                  القطة، فعل الناس كما فعل
                </h4>
              </Container>
            </Row>
          </Col>
          {/*  ///////////////////////////////////// */}
          <Col className="m-1">
            <Row>
              <Container className="border border-light rounded-4 shadow-lg p-3 mt-3 mb-3">
                <h3>
                  {" "}
                  <FaTasks style={iconStyle} color="white" />
                  أنشطة المشروع
                </h3>
                {ptypes.map((types, index) => (
                  <h4 className="m-2">
                    <Popup
                      ref={ref}
                      trigger={
                        <button className="popupbtn">
                          <li key={index}>{types}</li>
                        </button>
                      }
                      position="top center"
                      className="eventscard"
                      arrow={false}
                      modal
                      closeOnDocumentClick={false}
                    >
                      <button
                        className="close bg-indicatorbackground"
                        onClick={closePopup}
                      >
                        X
                      </button>
                      <ImageSlider images={images} />
                      <h4 className="text-white text-end lateef">
                        يركز المشروع على بناء القدرات المهنية وتعزيز المهارات
                        الحياتية الأساسية مثل التفكير النقدي وحل المشكلات، مع
                        تشجيع اليافعين على المشاركة الفعالة في المبادرات
                        المجتمعية. يهدف المشروع إلى تأهيل جيل قادر على التكيف مع
                        التحديات المستقبلية والمساهمة في إحداث تأثير إيجابي
                        ومستدام في مجتمعاتهم.
                      </h4>
                    </Popup>
                  </h4>
                ))}
              </Container>
            </Row>
            <Row>
              <Container className="border border-light rounded-4 shadow-lg p-3 mt-3 mb-3">
                <h3>
                  {" "}
                  <FaExclamationTriangle style={iconStyle} color="white" />
                  التحديات
                </h3>
                <h4>
                  كانت عجماء فأفصحت في أيامك ، وكانت خرساء فأنطقها جمالك ، فوقفت
                  على السرو والدَّوح من خطبائك ، فلما غنت حركت أشجان الإنسان ،
                  وأوحت إليه بالمعاني الحسان ، فأفاض الشعراء في وصفها ، وبكوا
                  لبكائها ، وتغنوا من غنائها .
                </h4>
              </Container>
            </Row>
            <Row sm={12}>
              {" "}
              <Container className="border border-light rounded-4 shadow-lg p-3 mt-3 mb-3">
                <h3>
                  {" "}
                  <FaMapSigns style={iconStyle} color="white" /> الخطة
                  المستقبلية
                </h3>
              </Container>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default App;
